import React from "react";
import PropTypes from "prop-types";
import { Container, Grid, Divider } from "@material-ui/core";
import "./layout.css"
import ScrollAnimation from 'react-animate-on-scroll';
import showdown from 'showdown';
import { Element } from 'react-scroll';

import image from "../img/lady_justice.png"

export default function RoleSection(props){

const converter = new showdown.Converter();

  const {
    heading,
    description
  } = props;

  return (
    <Element name="role-section">
      <section className="altSection role roleBackgroundImage"  style={{backgroundImage: `url(${image})`}}>
        <ScrollAnimation animateIn="fadeIn">
          <Container maxWidth="md">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} className="sectionHeaderCenter">
                <h2>{heading}</h2>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={12}>
                <div className="bodyText" dangerouslySetInnerHTML={{ __html: converter.makeHtml(description) }} />
              </Grid>
            </Grid>
          </Container>
        </ScrollAnimation>
      </section>
    </Element>
  );
};

RoleSection.propTypes = {
props: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      subHeading: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};

