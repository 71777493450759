import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import RoleSection from "../components/RoleSection";

// eslint-disable-next-line
export const TheRolePageTemplate = ({ heading, description, image }) => {

  return (
    <RoleSection heading={heading} description={description} />
  );
};

TheRolePageTemplate.propTypes = {
  heading: PropTypes.string.isRequired,
};

const TheRolePage = ({ data }) => {

  const { markdownRemark: post } = data;

  return (
    <Layout>
      <TheRolePageTemplate heading={post.frontmatter.heading} description={post.frontmatter.description} image={post.frontmatter.image} />
    </Layout>
  );
};

TheRolePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TheRolePage;

export const TheRolePageQuery = graphql`
  query TheRolePageTemplate {
    markdownRemark(frontmatter: {templateKey: {eq: "therole-page"}}) {
      frontmatter {
        heading
        description
        image {
          childImageSharp {
            gatsbyImageData(
              quality: 100
            )
          }
        }
      }
    }
  }
`;
